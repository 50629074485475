.App {
  text-align: center;
  height: 100vh;
  background-color: #202342;
  color: white;
}

.title {
  font-size: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.tabContainer {
  text-align: center;
}

.tab,
.tabSelected {
  font-size: 20px;
  padding: 10px;
  margin: 1px;
  cursor: pointer;
  display: inline-block;
  background-color: #2d325a;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.tabSelected {
  border-bottom: 2px solid white;
  background-color: #4f7bff;
}
